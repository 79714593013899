<template>
  <v-dialog v-model="visibility" persistent width="460px">
    <v-card>
      <v-col style="text-align: center; padding: 20px" v-if="!isBooking">
        <img
          src="@/assets/img/iconsSvg/paymentIcon.svg"
          alt="payment"
          style="margin: 20px"
        />
        <p class="title" style="margin-top: 10px; margin-bottom: 12px">
          {{ "payment_modal_title" | localize }}
        </p>
        <p class="text">
          {{ "payment_modal_description" | localize }}
        </p>
        <div class="sectionLine" style="margin: 20px 0px"></div>
        <v-row no-gutters align="center" style="margin-bottom: 8px">
          <div class="userIcon" style="margin-right: 8px" />
          <span style="color: #4b5262; font-size: 16px; font-weight: 600">
            {{ detailOrder?.tickets?.length }}
            {{ getPassengerText(detailOrder?.tickets?.length) }}
          </span>
        </v-row>
        <v-row
          no-gutters
          justify="space-between"
          v-for="(passanger, key, index) in passangerTypesForUser"
          :key="(passanger.id, index)"
        >
          <v-col class="px-0 py-0">
            <v-row
              no-gutters
              align="center"
              justify="space-between"
              v-for="item in passanger"
              :key="item.id"
              :style="'margin-top: 12px;'"
            >
              <span style="font-size: 14px; font-weight: 400"
                >{{ item.length }} {{ item?.[0]?.title }}</span
              >
              <span style="font-size: 14px; color: #4b5262; font-weight: 600"
                >{{ parseFloat(key) * item.length }} грн</span
              >
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          :style="'margin-top: 12px;'"
          v-if="discountAmount > 0"
        >
          <span style="font-size: 14px; font-weight: 400">{{
            "discount_label" | localize
          }}</span>
          <span style="font-size: 14px; color: #4b5262; font-weight: 600"
            >-{{ discountAmount.toFixed(2) }} грн</span
          >
        </v-row>
        <div class="sectionLine" style="margin: 20px 0px"></div>
        <div class="backgroundTotalPrice">
          <v-row no-gutters align="center" justify="space-between">
            <span class="title">До сплати</span>
            <span>{{ totalPrice.toFixed(2) }} грн</span>
          </v-row>
        </div>
        <div
          style="
            border-radius: 10px;
            background: #f5f5f5;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          "
        >
          <img
            src="@/assets/img/iconsSvg/bonusIcon.svg"
            alt="bonus icon"
            style="margin-right: 8px"
          />
          <span style="font-size: 14px; font-style: normal; font-weight: 400"
            >+{{ (totalPrice * bonus_amount).toFixed(2) }}
            {{ "quantity_bonus_label" | localize }}</span
          >
        </div>
        <div class="sectionLine" style="margin: 20px 0px"></div>
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="margin-top: 20px"
        >
          <div v-html="liqPayBtn" style="color: red" />
          <div v-html="blinkBtn" style="color: red" />
          <!-- <button class="bookingBtn text" @click="bookingTicket">
            Забронювати
            <img
              src="@/assets/img/iconsSvg/helpCircleIcon.svg"
              alt="help icon"
              style="margin-left: 8px"
            />
          </button> -->
          <cancel-btn
            text="Скасувати"
            style="margin-top: 20px"
            @click="$emit('close')"
          />
        </v-row>
      </v-col>
      <v-col v-else style="padding: 20px; text-align: center">
        <booking-ticket-time-box :booking_at="new Date()" />
        <img
          src="@/assets/img/iconsSvg/successBookingTicket.svg"
          alt="success booking"
          style="margin: 20px 0px"
        />
        <p class="title" style="margin-bottom: 12px">
          {{ "success_booking_ticket_title" | localize }}
        </p>
        <p class="text" style="margin: 0px">
          {{ "success_booking_ticket_description" | localize }}
        </p>
        <button
          class="bookingBtn"
          style="
            background-color: #149e51;
            color: #eeeeee;
            margin-top: 20px;
            margin-bottom: 20px;
          "
          @click="$router.push('/profile/my-ticket')"
        >
          {{ 'push_to_profile' | localize }}
        </button>
        <cancel-btn
          style="margin-top: 20px"
          text="Закрити"
          @click="$router.push('/')"
        />
      </v-col>
    </v-card>
  </v-dialog>
</template>
      
      <script>
import modalMixin from "@/mixins/modalMixin";
import cancelBtn from "../../UI/Buttons/cancelBtn.vue";
import bookingTicketTimeBox from "../../UI/bookingTicketTimeBox.vue";
export default {
  mixins: [modalMixin],
  components: {
    cancelBtn,
    bookingTicketTimeBox,
  },
  data: () => ({
    isBooking: false,
    bonus_amount: parseFloat(localStorage.getItem("bonus_amount")) / 100,
  }),
  props: {
    liqPayBtn: {
      require: true,
    },
    blinkBtn: {
      require: true,
    },
    totalPrice: {
      require: true,
    },
    passangerTypesForUser: {
      require: true,
    },
    detailOrder: {
      require: true,
    },
    discountAmount: {
      require: true,
    },
  },
  methods: {
    bookingTicket() {
      this.isBooking = true;
    },
    getPassengerText(count) {
      if (count === 1) {
        return this.$options.filters.localize("passanger_label").toLowerCase();
      } else if (count > 1 && count < 5) {
        return this.$options.filters.localize("passangers_label").toLowerCase();
      } else {
        return this.$options.filters
          .localize("to_many_passanger_label")
          .toLowerCase();
      }
    },
  },
};
</script>
      
      <style>
.title {
  color: #1b1b1b;
  text-align: center;
  font-family: "MacPaw Fixel Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text {
  color: #4d4d4d;
  text-align: center;
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.backgroundTotalPrice {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 16px;
  width: 100%;
  color: #144fa9;
  font-family: "MacPaw Fixel Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bookingBtn {
  display: flex;
  padding: 5px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fbbb55;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  color: #1b1b1b;
  font-weight: 500;
  width: 100%;
  height: 48px;
  margin-top: 20px;
}
input[name="btn_text"] {
  width: 420px !important;
  height: 48px !important;
  object-fit: cover !important;
  border-radius: 10px;
}
</style>