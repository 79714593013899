import requestService from "../requestService";
import store from "@/store";

export default {
    async getOrders(search, page) {
        const response = await requestService.get(`/v1/order?page=${page}&search=${search}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async getOrderById(id) {
        const response = await requestService.get(`/v1/order/${id}`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async getOrderByIdWithoutAuth(id) {
        const response = await requestService.get(`/v1/order_without_auth/${id}`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async getOrderByUuid(uuid) {
        const response = await requestService.get(`/v1/order/${uuid}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async getOrderByUuIdWithoutAuth(uuid) {
        const response = await requestService.get(`/v1/order_without_auth/${uuid}`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async getOrderByQr(id) {
        const response = await requestService.get(`/v1/order/scan/${id}`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async bookingTicket(form) {
        const response = await requestService.post(`/v1/order`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async bookingTicketWithoutAuth(form) {
        const response = await requestService.post(`/v1/order_without_auth`, form);
        return response?.data
    },
    async createOrder(form, order_uid) {
        const response = await requestService.post(`/v1/order/${order_uid}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async createOrderWithoutAuth(form, order_uid) {
        const response = await requestService.post(`/v1/order_without_auth/${order_uid}`, form);
        return response?.data
    },
    async updateOrder(form, order_uid) {
        const response = await requestService.post(`/v1/order/${order_uid}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async updateOrderWithoutAuth(form, order_uid) {
        const response = await requestService.post(`/v1/order_without_auth/${order_uid}`, form);
        return response?.data
    },
    async confirmPay(order_uid) {
        const response = await requestService.post(`/v1/order/confirm_pay/${order_uid}`,{},{
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async changeOrderStatus(id, form) {
        const response = await requestService.post(`/cp_dash/order/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async changeOrderStatusForUser(id, form) {
        const response = await requestService.post(`/order/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async sendOrderToEmail(uuid) {
        const response = await requestService.get(`/order/ticket/${uuid}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async getOrderMessages() {
        const response = await requestService.get(`/cp_dash/order_message`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async getOrderMessagesByStatus(status) {
        const response = await requestService.get(`/cp_dash/order_message/list/${status}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async getOrderMessage(id) {
        const response = await requestService.get(`/cp_dash/order_message/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async updateStatusOrderMessage(id, form) {
        const response = await requestService.post(`/cp_dash/order_message/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async deleteOrderMessage(id) {
        const response = await requestService.get(`/cp_dash/order_message/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async changeSeatsQuantity(id, form) {
        const response = await requestService.post(`/v1/order/seats/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async changeSeatsQuantityWithoutAuth(id, form) {
        const response = await requestService.post(`/v1/order_without_auth/seats/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async cancelOrder(uuid) {
        const response = await requestService.post(`/v1/order/cancel/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async cancelOrderWithoutAuth(uuid) {
        const response = await requestService.post(`/v1/order_without_auth/cancel/${uuid}`, {}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async payTicket(id) {
        const response = await requestService.post(`/v1/order/pay/${id}`, {}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async getPayBtns(id) {
        const response = await requestService.get(`/v1/order/pay/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async applyPromocode(id, form) {
        const response = await requestService.post(`/v1/order/apply_promocode/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async applyPromocodeWithoutAuth(id, form) {
        const response = await requestService.post(`/v1/order_without_auth/apply_promocode/${id}`, form);
        return response?.data
    },
    async asignOrder(id, form) {
        const response = await requestService.post(`/v1/order/assign/${id}`, form);
        return response?.data
    },
    async useBonuses(id, form) {
        const response = await requestService.post(`/v1/order/use_bonus/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
    async declineBonuses(id, form) {
        const response = await requestService.post(`/v1/order/decline_bonus/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        });
        return response?.data
    },
}